let dataCategory = [
    {
        id: 1,
        name: 'Mouse'
    },
    {
        id: 2,
        name: 'Keyboard'
    },
    {
        id: 3,
        name: 'Bag'
    },
    {
        id: 4,
        name: 'Stationary'
    },
]

export default dataCategory